import filterObject from '../../utils/filterObject'
import { itemsToAnonymize } from '../constants'

export default function anonymizeProperties() {
  const trackOriginal = globalThis.analytics?.track
  const pageOriginal = globalThis.analytics?.page

  function anonymizeTrackProperties({
    userId: _userid,
    event,
    anonymousId,
    properties,
    context,
  }) {
    if (!anonymousId) return

    const anonymizedProperties = filterObject(properties, itemsToAnonymize)
    //@ts-expect-error
    return trackOriginal({
      userId: anonymousId,
      event,
      anonymousId,
      properties: anonymizedProperties,
      context,
    })
  }

  function anonymizePageProperties({
    userId: _userid,
    name,
    anonymousId,
    properties,
    context,
  }) {
    if (!anonymousId) return
    const anonymizedProperties = filterObject(properties, itemsToAnonymize)

    return pageOriginal({
      userId: anonymousId,
      name,
      anonymousId,
      properties: anonymizedProperties,
      context,
    })
  }

  //@ts-expect-error
  globalThis.analytics.track = anonymizeTrackProperties
  //@ts-expect-error
  globalThis.analytics.page = anonymizePageProperties
}
