import { useEffect } from 'react'

const useOneTrust = (): void => {
  const oneTrustLoaded =
    typeof window !== 'undefined' && (window as any).OneTrust
  useEffect(() => {
    if (typeof window !== 'undefined') {
      ;(window as any).OneTrust?.OnConsentChanged(async () => {
        const fullUrl = new URL(
          '/api/auth/consent-change',
          window?.location?.origin
        )

        await fetch(fullUrl, {
          method: 'POST',
        })
      })
    }
  }, [oneTrustLoaded])
}

export default useOneTrust
