import { IApplicationState } from '@bees-web/nfa-types'
import { QueryParams } from '../../interfaces'
import { objectToSearchParams } from '../url'
import processStateExpression from './processStateExpression'

export type JokerResource = 'pages' | 'blocks'

export default function jokerUrlBuilder(
  resource: JokerResource,
  params: QueryParams,
  state: IApplicationState = null
): string {
  const { pageName, blockName, ...queryParams } = params
  const processedParams: QueryParams = state ? queryParams : {}

  const processQuery = (value: string): string =>
    processStateExpression(value, state)

  const queryParam = objectToSearchParams(
    processedParams,
    processQuery
  ).toString()

  return `api/${resource}/${pageName || blockName}?${queryParam}`
}
