export default function filterObject(obj: any, keyList: string[]): any {
  if (typeof obj !== 'object') return obj
  const filteredProperties: any = {}
  for (const key in obj) {
    const value = obj[key]
    const itemToFilter = keyList.find((i) => i === key)
    if (itemToFilter) {
      filteredProperties[key] = null
    } else if (typeof value === 'object') {
      filteredProperties[key] = filterObject(value, keyList)
    } else {
      filteredProperties[key] = value
    }
  }
  return filteredProperties
}
