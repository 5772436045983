export const TRACK_PAGE = 'TRACK_PAGE'

export const itemsToAnonymize = [
  'requester_phone_number',
  'multi_poc_total',
  'multi_poc',
  'business_address_city',
  'tax_id',
  'requester_email',
  'business_address_longitude',
  'business_address_street',
  'ncr_poc_status',
  'business_address_state',
  'tax_id_type',
  'business_address_more_info',
  'business_address_neighborhood',
  'user_role_inside_poc',
  'business_address_latitude',
  'business_address_number',
  'business_address_postal_code',
  'total_user_pocs',
  'unique_user_id',
  'poc_id',
  'poc_country',
  'number_pocs',
  'poc_name',
  'poc_rank',
  'social_media_user_id',
  'phone_validation_error',
  'suggested_address_postal_code',
  'suggested_address_street',
  'suggested_address_city',
  'suggested_address_number',
  'suggested_address_state',
  'user_email',
  'user_phone_number',
  'used_location_latitude',
  'used_location_longitude',
  'user_id',
  'campaign_unique_user_id',
  'selected_poc_rank',
  'input_page_phone',
]
