import account from '@bees-web/nfa-interactive-account'
import algoSelling from '@bees-web/nfa-interactive-algo-selling'
import cart from '@bees-web/nfa-interactive-cart'
import checkout from '@bees-web/nfa-interactive-checkout'
import customerExperience from '@bees-web/nfa-interactive-customer-experience'
import deals from '@bees-web/nfa-interactive-deals'
import digitalServices from '@bees-web/nfa-interactive-digital-services'
import downloadTemplate from '@bees-web/nfa-interactive-download-template'
import finances from '@bees-web/nfa-interactive-finances'
import home from '@bees-web/nfa-interactive-home'
import linkBanners from '@bees-web/web-interactive-link-banners'
import linkCampaigns from '@bees-web/web-interactive-link-campaigns'
import linkCart from '@bees-web/web-interactive-link-cart'
import linkCheckout from '@bees-web/web-interactive-link-checkout'
import linkCurationship from '@bees-web/link-interactive-curationship'
import link from '@bees-web/nfa-interactive-link'
import linkHome from '@bees-web/web-interactive-link-home'
import linkInsights from '@bees-web/web-interactive-link-insights'
import linkKam from '@bees-web/web-interactive-link-kam'
import linklastMile from '@bees-web/web-interactive-link-last-mile'
import linkOrderTracking from '@bees-web/web-interactive-link-order-tracking'
import linkTermsAndConditions from '@bees-web/web-link-interactive-terms-and-conditions'
import onboardingAutomation from '@bees-web/link-interactive-onboarding-automation'
import payments from '@bees-web/nfa-interactive-payments'
import postSales from '@bees-web/nfa-interactive-post-sales'
import productOrder from '@bees-web/nfa-interactive-product-order'
import products from '@bees-web/nfa-interactive-products'
import rewards from '@bees-web/nfa-interactive-rewards'
import search from '@bees-web/nfa-interactive-search'
import uploadOrder from '@bees-web/nfa-interactive-upload-order'
import webEuFinance from '@bees-web/web-eu-finance'
import coreVS from './core-vs'
import getDependencies from '../utils/VL/getDependencies'
import webCollabForecast from '@bees-web/web-interactive-collab-forecast'

export default getDependencies([
  account,
  algoSelling,
  cart,
  checkout,
  customerExperience,
  deals,
  digitalServices,
  downloadTemplate,
  finances,
  home,
  link,
  linkBanners,
  linkCampaigns,
  linkCart,
  linkCheckout,
  linkCurationship,
  linkHome,
  linkInsights,
  linkKam,
  linklastMile,
  linkOrderTracking,
  linkTermsAndConditions,
  onboardingAutomation,
  payments,
  postSales,
  productOrder,
  products,
  rewards,
  search,
  uploadOrder,
  webEuFinance,
  coreVS,
  webCollabForecast,
])
