const smartEval: (
  lookup: { [key: string]: any },
  expr: string,
  returnExpr?: boolean,
  defaultValue?: any
) => any = (lookup, expr, returnExpr, defaultValue) => {
  let result

  try {
    for (const k in lookup) {
      new Function('value', k + ' = value')(lookup[k]) // attempt to set the value on global scope
    }
    result = eval(`(${expr})`)
    for (const k in lookup) {
      new Function('value', k + ' = value')(undefined) // reset the value to undefined
    }
  } catch (e) {
    console.log(
      'SmartEval',
      JSON.stringify({
        error: e.message,
      })
    )
    return defaultValue
  }
  if (result === undefined || result === '' || result === null) {
    if (returnExpr) return expr
  }
  return result === undefined || result === lookup ? defaultValue : result
}

export default smartEval
